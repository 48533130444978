import React from 'react'
import { Link } from 'gatsby'
import { Button, Box, Heading, Text } from 'theme-ui'

/**
 * Custom Shadowed Override
 */

const styles = {
    author: {
        display: `inline-block`,
        color: `alpha`
    },
    occupation: {
        mb: 4
    },
    specialty: {
        color: `text`,
        mb: 4
    }
}

export default () => (
    <>
        <Heading variant='h1'>
            Hi, I'm <Text sx={styles.author}>Coop</Text>.
        </Heading>
        <Heading variant='h1' sx={styles.occupation}>
            Software Engineer
        </Heading>
        <Heading variant='h3' sx={styles.specialty}>
            Specialized in Web and Game Development
        </Heading>
        <Box variant='buttons.group'>
            <Button as={Link} to='https://www.twitter.com/coop5656' target="_blank">
                Contact Me
            </Button>
            <Button variant='white' as={Link} to='/about'>
                About Me
            </Button>
        </Box>
    </>
)
